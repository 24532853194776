import React from 'react';

import styles from './ArticleTile.module.css';

export function ArticleTile({title, source, url}) {
  return (
    <div className={styles.Container}>
      <p className={styles.Title}>{title}</p>
      <p className={styles.Source}>{source}</p>
      <a
        href={url}
        target="_blank"
        className={styles.Link}
        rel="noopener noreferrer"
      >
        Read the article <span className={styles.Arrow}>&rarr;</span>
      </a>
    </div>
  );
}
