import React, {useState} from 'react';

import {RangeInput} from '../common/RangeInput';

import styles from './HowItWorks.module.css';

const formatNumber = new Intl.NumberFormat('en').format;
const formatDollars = new Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'USD',
}).format;

export function HowItWorks() {
  const [price, setPrice] = useState(150);
  const [numPatients, setNumPatients] = useState(100);
  const [avgDownstream, setAvgDownstream] = useState(500);

  return (
    <section className={styles.Container}>
      <h2 className={styles.HowItWorksHeading}>How it works</h2>
      <div className={styles.GridContainer}>
        <div>
          <div className={styles.StepContainer}>
            <h3 className={styles.StepHeading}>1. Create your plan</h3>
            <p>
              Create your membership plan in Plum. It takes less than 5 minutes.
              Build from an existing plan, or start fresh!
            </p>
          </div>

          <div className={styles.StepContainer}>
            <h3 className={styles.StepHeading}>2. Launch your plan</h3>
            <p>
              <strong>Launching a membership plan is a breeze.</strong> Our
              5-step guide for launching a plan will ensure​ your success. Plus
              you get free access to world-class customer support.
            </p>
          </div>

          <div className={styles.StepContainer}>
            <h3 className={styles.StepHeading}>3. Watch your plan grow</h3>
            <p>
              <strong>Patients will start to sign up!</strong> The magic of Plum
              is that patients save money at the same time you increase the
              profitability of your practice. That's just what happens when you
              cut out a middleman who's not adding value. Patient loyalty
              increases, and you get to have the kind of practice you've always
              wanted—a patient-friendly one.
            </p>
          </div>
        </div>
        <div className={styles.CalculatorCard}>
          <h3 className={styles.CalculatorHeading}>Plan revenue calculator</h3>

          <div className={styles.InputContainer}>
            <div className={styles.LabelContainer}>
              <label className={styles.Label} for="numMembers">
                Number of members
              </label>
              <p>{formatNumber(numPatients)}</p>
            </div>
            <RangeInput
              id="numMembers"
              name="numMembers"
              min={10}
              max={500}
              step={5}
              value={numPatients}
              onChange={event => setNumPatients(event.target.value)}
            />
          </div>

          <div className={styles.InputContainer}>
            <div className={styles.LabelContainer}>
              <label className={styles.Label} for="planPrice">
                Plan Price
              </label>
              <p>{formatDollars(price)} / yr</p>
            </div>
            <RangeInput
              id="planPrice"
              name="planPrice"
              min={10}
              max={350}
              step={5}
              value={price}
              onChange={event => setPrice(event.target.value)}
            />
          </div>

          <div className={styles.InputContainer}>
            <div className={styles.LabelContainer}>
              <label className={styles.Label} for="avgDownstream">
                Average downstream
              </label>
              <p>{formatDollars(avgDownstream)} / yr</p>
            </div>
            <RangeInput
              id="avgDownstream"
              name="avgDownstream"
              min={100}
              max={2000}
              step={5}
              value={avgDownstream}
              onChange={event => setAvgDownstream(event.target.value)}
            />
          </div>

          <div className={styles.TotalContainer}>
            <div className={styles.LabelContainer}>
              <p>
                <strong>Total</strong>
              </p>
              <p>
                <strong>
                  {formatDollars(
                    price * numPatients + avgDownstream * numPatients,
                  )}{' '}
                  / yr
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
