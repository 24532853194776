import React from 'react';

import styles from './WhatIsAMembership.module.css';

export function WhatIsAMembership() {
  return (
    <div className={styles.Container}>
      <h2 className={styles.Heading}>
        Q. What <em>is</em> a membership plan?
      </h2>

      <p className={styles.BasicDefinition}>
        An in-house membership plan is a subscription to your practice.
      </p>

      <p className={styles.LargeExplanation}>
        For most members, the plan will take the place of dental insurance in
        their annual budget—and it will be a way better deal. When you deploy an
        in-house membership plan, you cut out the middleman and split the added
        value with your patient.
      </p>
    </div>
  );
}
