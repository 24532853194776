import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

import {Button} from '../common/Button';

import styles from './YourPlan.module.css';

export function YourPlan({setModalOpen}) {
  const imageData = useStaticQuery(graphql`
    query {
      patient: file(relativePath: {eq: "patient.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      dentist: file(relativePath: {eq: "male-dentist.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={styles.Container}>
      <div className={styles.Images}>
        <div className={styles.Patient}>
          <Img fluid={imageData.patient.childImageSharp.fluid} />
        </div>

        <div className={styles.Dentist}>
          <Img fluid={imageData.dentist.childImageSharp.fluid} />
        </div>
      </div>

      <div className={styles.Text}>
        <h3 className={styles.KnowText}>
          You know your practice. <br className={styles.KnowTextBreak} />
          You know your patients.
        </h3>
        <h2>
          <span className={styles.YourPlan}>It's your plan.</span>
          <span className={styles.WePowerIt}>We just power it.</span>
        </h2>

        <div className={styles.Button}>
          <Button onClick={() => setModalOpen(true)}>Request a demo</Button>
        </div>
      </div>
    </div>
  );
}
