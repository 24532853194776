import '@reach/dialog/styles.css';
import React, {useState} from 'react';
import {Dialog} from '@reach/dialog';
import VisuallyHidden from '@reach/visually-hidden';

import styles from './RequestDemoModal.module.css';
import {Button} from '../common/Button';

const EMAIL_ENDPOINT = './.netlify/functions/request-demo';

export function RequestDemoModal({onDismiss, open}) {
  const [status, setStatus] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');

  async function handleSubmit(event) {
    event.preventDefault();

    setStatus('LOADING');

    try {
      const response = await fetch(
        `${EMAIL_ENDPOINT}?name=${name}&email=${email}&phone=${phone}&description=${description}`,
        {
          method: 'GET',
        },
      );

      if (response.status === 200) {
        setStatus('SUCCESS');
      } else {
        setStatus('ERROR');
      }
    } catch (error) {
      setStatus('ERROR');
    }
  }

  return (
    <Dialog
      isOpen={open}
      onDismiss={() => {
        setStatus();
        onDismiss();
      }}
      className={styles.Dialog}
    >
      <button className={styles.CloseButton} onClick={onDismiss}>
        <VisuallyHidden>Close</VisuallyHidden>
        <span aria-hidden>×</span>
      </button>

      <h3 className={styles.Heading}>Request a demo</h3>
      <p>
        Thank you for your interest in Plum! Please enter your information below
        and we will contact you as soon as possible.
      </p>

      <form method="POST" onSubmit={handleSubmit}>
        <label className={styles.Label} for="name">
          Your name*
        </label>
        <input
          id="name"
          type="text"
          className={styles.Input}
          name="name"
          onChange={event => setName(event.target.value)}
          required
        />

        <label className={styles.Label} for="email">
          Your email*
        </label>
        <input
          id="email"
          type="text"
          className={styles.Input}
          name="email"
          required
          onChange={event => setEmail(event.target.value)}
        />

        <label className={styles.Label} for="phone">
          Your phone number*
        </label>
        <input
          id="phone"
          type="text"
          className={styles.Input}
          name="phone"
          required
          onChange={event => setPhone(event.target.value)}
        />

        <label className={styles.Label} for="description">
          Anything else you want to share with us!
        </label>
        <textarea
          id="description"
          className={styles.Input}
          name="description"
          onChange={event => setDescription(event.target.value)}
        />

        <div className={styles.ButtonContainer}>
          <Button block disabled={status === 'LOADING' || status === 'SUCCESS'}>
            {status === 'LOADING'
              ? 'Loading...'
              : status === 'SUCCESS'
              ? 'Success!'
              : 'Submit'}
          </Button>
        </div>

        <div className={styles.StatusTextContainer}>
          {status === 'SUCCESS' ? (
            <p className={styles.SuccessText}>
              Success! We will reach out to you soon.
            </p>
          ) : null}
          {status === 'ERROR' ? (
            <p className={styles.ErrorText}>
              Uh oh! Something went wrong. Please try again later.
            </p>
          ) : null}
        </div>
      </form>
    </Dialog>
  );
}
