import React, {useState} from 'react';

import Layout from '../components/layout';
import {
  Articles,
  HowItWorks,
  Intro,
  RequestDemoModal,
  Value,
  WhatIsAMembership,
  YourPlan,
} from '../components/Index';
import SEO from '../components/seo';

export default function IndexPage() {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Layout>
      <SEO title="Home" />

      <RequestDemoModal
        onDismiss={() => setModalOpen(false)}
        open={modalOpen}
      />

      <Intro setModalOpen={setModalOpen} />
      <HowItWorks />
      <WhatIsAMembership />
      <Value />
      <Articles />
      <YourPlan setModalOpen={setModalOpen} />
    </Layout>
  );
}
