import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

import styles from './Value.module.css';

export function Value() {
  const imageData = useStaticQuery(graphql`
    query {
      chair: file(relativePath: {eq: "dentist-chair.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      patient: file(relativePath: {eq: "dentist-and-patient.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={styles.Container}>
      <div className={styles.Value}>
        <h4 className={styles.Header}>Value for your practice</h4>

        <p className={styles.Body}>
          A membership plan will grow your practice without needing to add a
          single new patient. With a properly-priced membership plan,
          unprofitable patients can become profitable. Membership plans reduce
          administrative costs by reducing the amount of time spent billing and
          verifying insurance (not to mention the negotiating). And with Plum,
          managing your membership plan is easy.
        </p>
      </div>
      <Img
        className={styles.ChairImage}
        fluid={imageData.chair.childImageSharp.fluid}
      />

      <Img
        className={styles.PatientImage}
        fluid={imageData.patient.childImageSharp.fluid}
      />
      <div className={styles.Value}>
        <h4 className={styles.Header}>Value for your patients</h4>
        <p className={styles.Body}>
          Dental insurance, in the vast majority of cases, is a terrible deal
          for patients: they almost never use enough of their benefits to
          justify the premium that they pay. A membership plan cuts out the
          dental insurance company or discount plan, allowing the practice to
          earn more for the work that you do—plus the patient to pay less out of
          pocket. Everybody wins.
        </p>
      </div>
    </div>
  );
}
