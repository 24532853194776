import React from 'react';

import {ArticleTile} from './ArticleTile';

import styles from './Articles.module.css';

export function Articles() {
  return (
    <div className={styles.Container}>
      <h3>Don't just take our word for it...</h3>

      <div className={styles.Grid}>
        <ArticleTile
          title="The Dental Drilldown: Dental membership Plans 101"
          source="Dental Economics, June 2018"
          url="https://www.dentaleconomics.com/practice/patient-communication-and-patient-financing/article/16385005/the-dental-drilldown-dental-membership-plans-101"
        />

        <ArticleTile
          title="Perspectives on dental membership plans"
          source="Dental Economics, June 2018"
          url="https://www.dentaleconomics.com/practice/practice-management-software/article/16384929/perspectives-on-dental-membership-plans"
        />

        <ArticleTile
          title="The Rise of in-house dental plans"
          source="Dental Economics, March 2018"
          url="https://www.dentaleconomics.com/practice/article/16385144/the-rise-of-inhouse-dental-plans"
        />

        <ArticleTile
          title="In-office membership plans could build patient loyalty, revenue"
          source="ADA, June 2018"
          url="https://www.ada.org/en/publications/ada-news/2018-archive/june/in-office-membership-plans-could-build-patient-loyalty-revenue"
        />

        <ArticleTile
          title="Why every practice should consider an in-house membership plan"
          source="Revenue Well"
          url="https://www.revenuewell.com/article/dental-practice-membership-program/"
        />

        <ArticleTile
          title="Debunking myths about in-house dental membership programs"
          source="Dentistry IQ, October 2018"
          url="https://www.dentistryiq.com/practice-management/patient-relationships/article/16367745/debunking-myths-about-inhouse-dental-membership-programs"
        />
      </div>
    </div>
  );
}
