import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Button} from '../common/Button';

import styles from './Intro.module.css';

export function Intro({setModalOpen}) {
  const {plants} = useStaticQuery(graphql`
    query {
      plants: file(relativePath: {eq: "plants.png"}) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <div className={styles.Container}>
      <h1 className={styles.MainHeading}>
        Build, launch, and manage your in-house dental membership plan in
        minutes.
      </h1>
      <Button onClick={() => setModalOpen(true)}>Request a demo</Button>

      <div className={styles.Plants}>
        <Img fluid={plants.childImageSharp.fluid} />
      </div>
    </div>
  );
}
